import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Product from './Product';

const ProductList = ({ products, category }) => {
  return (
    <>
      {products ? (
        <Row>
          {products
            .filter((product) => {
              return (
                product.node.category.name === category || category === 'all'
              );
            })
            .map((product, index) => {
              return (
                <Col md={6} lg={4} key={index} className="py-2">
                  <Product product={product} />
                </Col>
              );
            })}
        </Row>
      ) : (
        <p>Er zijn momenteel geen producten. Kom later terug.</p>
      )}
    </>
  );
};

ProductList.defaultProps = {
  category: 'all',
};

ProductList.propTypes = {
  category: PropTypes.string,
};

export default ProductList;
