import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ProductList from './products/ProductList';
import Title from './Title';

const getFeaturedPlaces = graphql`
  query {
    featuredProducts: allContentfulProduct(filter: { featured: { eq: true } }) {
      edges {
        node {
          contentful_id
          name
          slug
          price
          category {
            name
          }
          image {
            fixed(width: 286, height: 286) {
              ...GatsbyContentfulFixed
            }
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;

const FeaturedProducts = () => {
  const products = useStaticQuery(getFeaturedPlaces).featuredProducts.edges;

  return (
    <>
      <Title title="Uitgelichte producten" />
      <ProductList products={products} />
    </>
  );
};

export default FeaturedProducts;
