import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const Product = ({ product }) => {
  const { image, name, price, slug, contentful_id } = product.node;

  return (
    <Card className="mr-auto ml-auto" style={{ width: '18rem' }}>
      <Img fixed={image.fixed} alt={name} />
      <Card.Body className="text-center">
        <Card.Title>{name}</Card.Title>
        <Card.Text>
          {new Intl.NumberFormat('nl', {
            style: 'currency',
            currency: 'EUR',
          }).format(price)}
        </Card.Text>
        <div className="d-flex justify-content-center">
          <Link to={`/producten/${slug}`}>
            <Button variant="outline-success" className="mr-2">
              Meer info
            </Button>
          </Link>
          <Button
            variant="success"
            className="snipcart-add-item"
            data-item-id={contentful_id}
            data-item-name={name}
            data-item-price={price}
            data-item-image={image.fluid.src}
            data-item-url="https://gatsby-store-poc.onrender.com"
          >
            Kopen
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Product;
