import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col, Button, Breadcrumb } from 'react-bootstrap';
import Image from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import FeaturedProducts from '../components/FeaturedProducts';

export const query = graphql`
  query($slug: String!) {
    product: contentfulProduct(slug: { eq: $slug }) {
      name
      image {
        fluid {
          src
          ...GatsbyContentfulFluid
        }
      }
      nutriScore
      description {
        description
      }
      price
      contentful_id
      slug
    }
  }
`;

const ProductTemplate = ({ data }) => {
  const {
    name,
    image,
    nutriScore,
    description,
    price,
    contentful_id,
    slug,
  } = data.product;

  return (
    <Layout pageInfo={{ pageName: 'producten' }}>
      <SEO title={`Producten - ${name}`} />
      <Container>
        <Breadcrumb className="mt-2">
          <Breadcrumb.Item href="/producten">Producten</Breadcrumb.Item>
          <Breadcrumb.Item active>{name}</Breadcrumb.Item>
        </Breadcrumb>
        <Row className="pb-2">
          <Col md={6}>
            <Image fluid={image.fluid} alt={name} />
          </Col>
          <Col md={6}>
            <h1>{name}</h1>
            <p>
              {new Intl.NumberFormat('nl', {
                style: 'currency',
                currency: 'EUR',
              }).format(price)}
            </p>
            <p>{description.description}</p>
            <p>
              Nutri-Score: {nutriScore} (
              <a href="https://www.health.belgium.be/nl/de-nutri-score-0">
                Wat is de Nutri-Score?
              </a>
              )
            </p>
            <Button
              variant="success"
              className="snipcart-add-item"
              data-item-id={contentful_id}
              data-item-name={name}
              data-item-price={price}
              data-item-image={image.fluid.src}
              data-item-url={`/producten/${slug}`}
            >
              Kopen
            </Button>
          </Col>
        </Row>
        <FeaturedProducts />
      </Container>
    </Layout>
  );
};

export default ProductTemplate;
